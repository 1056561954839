<template>
	<div class="box">
		<el-card class="box-card" v-for="(item, key, i) in caizhong" :key="key">
			<div class="tit1 mb6">{{item.txt}}</div>
			<div v-if="item.qsList.length == 0">获取数据失败</div>
			<template v-else>
				<div class="flex-bet li" v-for="(item1,index) in item.qsList" :key="index">
					<div class="fs14">期数：{{item1.issue_num}}，彩果：{{item1.win_num}}</div>
					<div class="fs14 c-blue curpoi" @click="open(key, item1.issue_num)">重新开奖</div>
				</div>
			</template>
		</el-card>		
	</div>
</template>

<script>
	let _t = undefined
	import { qishuNumber, reopenNumber } from '../../api/api';
	export default {
		data() {
			return {
				caizhong: {
					6: { txt: '大乐透', qishu: '', qsList: [] },
					7: { txt: '排列三', qishu: '', qsList: [] },
					8: { txt: '排列五', qishu: '', qsList: [] },
					9: { txt: '七星彩', qishu: '', qsList: [] },
					11: { txt: '福彩3d', qishu: '', qsList: [] },
					12: { txt: '双色球', qishu: '', qsList: [] }
				},
				form:{
					qishu: '',
					lottery_type:''
				}				
			}
		},
		created() {			
			_t = this;
			_t.init();
		},
		methods: {
			init(){
				Object.keys(_t.caizhong).forEach((key)=>{
					_t.getList( _t.caizhong[key].txt, key);
				})
			},
			open(key, qishu) {
				// if(!_t.caizhong[key].qishu) return _t.$message.error('选择期数！');
				const msg = '是否对【' + _t.caizhong[key].txt + '】, 第 ' + qishu + ' 期进行重新开奖？'
				_t.$confirm( msg , '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_t.submit(key, qishu)
				}).catch(() => {
					// _t.$message({ type: 'info', message: '已取消删除' });    
				});
			},
			// 重新开奖
			submit(key, qishu){
				_t.form.lottery_type = key
				_t.form.qishu = qishu
				reopenNumber(_t.form).then((res) => {
					if (res.code == 1) {
						_t.$notify({ title: '重置成功！', message: res.msg, type: 'success' });
						_t.init();
					} else {
						_t.$notify({ title: '重置出错！', message: res.msg, type: 'error' });
					}
				});
			},
			//获取数据
			getList(txt, val) {				
				qishuNumber({lottery_type: val}).then((res) => {
					if (res.code == 1) {
						_t.caizhong[val].qsList = res.data;
					} else {
						_t.$notify({
							title: txt + '获取期数错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},			
		},
	}
</script>

<style lang="less" scoped>
.box{ display: flex; flex-wrap: wrap; }
.box-card{ width:500px; margin: 0 20px 20px 0 ; }
.flex-bet{ display: flex; align-items: center; justify-content: space-between; }
.flex-ali-cen{ display: flex; align-items: center; }
.w1{ width: 300px; }
.mb6{ margin-bottom: 6px; }
.curpoi{ cursor: pointer; }
.fs14{ font-size: 14px; }
.tit1{ font-size: 16px; margin-right: 10px; }
.c-blue{ color: #409EFF;}
.li{ padding: 8px; 
	&:nth-child(2n){ background-color: #F2F6FC; }
}
</style>
